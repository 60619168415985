/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import ThemeProvider from './src/components/ThemeProvider';

// eslint-disable-next-line react/prop-types, import/prefer-default-export
// export const wrapRootElement = ({ element }) => (
//   <ThemeProvider>
//     {element}
//   </ThemeProvider>
// );
