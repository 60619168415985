import React from 'react';
import {
  createMuiTheme, MuiThemeProvider, ThemeProviderProps, responsiveFontSizes,
} from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const colors = {
  gradient_blue: 'linear-gradient(180deg, #807EE6 27.27%, #4B90D0 97.54%)',
  gradient_purple: 'linear-gradient(180deg, #864BD0 0%, #BE81CB 100%);',
  gradient_orange: 'linear-gradient(180deg, #F97070 0%, #D69C45 100%);',
  gradient_green: 'linear-gradient(180deg, #42B189 0%, #81DB62 100%);',
};

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    colors: typeof colors;
  }
  interface PaletteOptions {
    colors: typeof colors;
  }
}

const breakpoints = createBreakpoints({});

const Theme = responsiveFontSizes(createMuiTheme({
  palette: {
    background: { default: 'white' },
    primary: {
      main: '#484848',
    },
    text: {
      primary: '#484848',
      secondary: '#656565',
    },
    colors,
  },
  typography: {
    fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    fontWeightBold: 700,
    fontWeightRegular: 500,
    h1: {
      fontSize: '4rem',
      fontWeight: 900,
    },
    h2: {
      fontSize: '2.3rem',
      fontWeight: 900,
    },
    body1: {
      fontSize: '1.1rem',
    },
    body2: {
      fontSize: '0.9rem',
    },
  },
  overrides: {
    MuiContainer: {
      root: {
        padding: '0px 80px',
        width: 'calc(100% - 20px)',
        [breakpoints.up('lg')]: {
          width: 'calc(100% - 200px)',
        },
        [breakpoints.up('sm')]: {
          width: 'calc(100% - 100px)',
        },
      },
    },
    MuiInputBase: {
      input: {
        marginTop: '4px',
      },
    },
  },
  spacing: 4,
}));

const ThemeProvider = ({ children }: Pick<ThemeProviderProps, 'children'>) => (
  <MuiThemeProvider theme={Theme}>
    <CssBaseline />
    {children}
  </MuiThemeProvider>
);

export default ThemeProvider;
